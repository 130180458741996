import React, {useState} from 'react';
import env from "react-dotenv";
import LoadingSpinner from "../components/loadingspinner";
import {useNavigate} from 'react-router-dom';
import "react-widgets/styles.css";
import DropdownList from "react-widgets/DropdownList";

import ReCAPTCHA from "react-google-recaptcha";
import validator from 'validator' 

const BE = env.API_ENDPOINT;

const axios = require('axios');

function Checkout({ cart, setCart }) {

    const validatePhoneNumber = (number) => {
        const isValidPhoneNumber = validator.isMobilePhone(number.trim(), "ro-RO")
        console.log("validatePhoneNumber: " + isValidPhoneNumber);
        return (isValidPhoneNumber)
    }

    const validateEmail = (em) => {
        const errors = {}
        let result = false;
        if (!em) {
            errors.email = 'Required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(em.trim())) {
            errors.email = 'Invalid email address';
        } else {
            result = true;
        }

        console.log("validateEmail: " + result);
      
        return result;
    }

    const [checkoutProgress, setCheckoutProgress] = useState(false);
    const navigate = useNavigate();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [clientDetails, setClientDetails] = useState('');
    const [courier, setCourier] = useState('FanCourier');
    const [country, setCountry] = useState('Romania');
    const [zipCode, setZipCode] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');


    const [isCorrectForm, setIsCorrectForm] = useState(false);

    const placeOrder = () => {

        setCheckoutProgress(true);

        let orderItemList = [];

        cart.forEach(element => {
            orderItemList.push({product: element.product.id, quantity: element.quantity})
        });

        axios.post(
            BE + `/api/order/create`, 
            {
                'orderItemList': orderItemList,
                "city": city,
                "county": state,
                "courier": courier,
                "zip_code": zipCode.trim(),
                "street": address,
                "street_details": address,
                "country":country,
                "first_name": firstName,
                "last_name": lastName,
                "client_details": clientDetails,
                "email": email.trim(),
                "telephone": phone.trim()
            }
        ).then((rr) => {
                console.log(rr); 
                console.log(rr.data.id);
                setCheckoutProgress(false); 
                setCart([]);
                navigate('/tracking/' + rr.data.id);
        })
        .catch(function (error) {
            console.log(error);
            setCheckoutProgress(false);
            console.log('Ups s-a produs o erroare la plasarea comenzii.');
            /* Show an error here!*/
        });
    }

    function onChange(value) {
        console.log("Captcha value:", value);
    }

    const setFormValue = (k,v) => {

        let isCorrectFirstName = false;
        let isCorrectLastName = false;
        let isCorrectEmail = false;
        let isCorrectPhone = false;
        let isCorrectAddress = false;
        let isCorrectCountry = false;
        let isCorrectState = false;
        let isCorrectZipCode = false;
        let isCorrectCity = false;

        if (firstName && firstName !== "" ) {
            isCorrectFirstName = true;
        }

        if (lastName && lastName !== "" ) {
            isCorrectLastName = true;
        }

        if (email && email !== "" && validateEmail(email)) {
            isCorrectEmail = true;
        }

        if (phone && phone !== "" && validatePhoneNumber(phone)) {
            isCorrectPhone = true;
        }

        if (address && address !== "") {
            isCorrectAddress = true;
        }

        if (country && country !== "") {
            isCorrectCountry = true;
        }

        if (city && city !== "") {
            isCorrectCity = true;
        }

        if (state && state !== "") {
            isCorrectState = true;
        }

        if (zipCode && zipCode !== "") {
            isCorrectZipCode = true;
        }

        switch(k) {
            case 'firstName':
                setFirstName(v);
                if ((v && v !== "")) {
                    isCorrectFirstName = true;
                } else {
                    isCorrectFirstName = false;
                }
                break;
            case 'lastName':
                setLastName(v);
                if ((v && v !== "")) {
                    isCorrectLastName = true;
                } else {
                    isCorrectLastName = false;
                }
                break;
            case 'email':
                setEmail(v);
                if ((v && v !== "") && validateEmail(v)) {
                    isCorrectEmail = true;
                } else {
                    isCorrectEmail = false;
                }
                break;
            case 'phone':
                setPhone(v);
                if ((v && v !== "") && validatePhoneNumber(v)) {
                    isCorrectPhone = true;
                } else {
                    isCorrectPhone = false;
                }
                break;
            case 'address':
                setAddress(v);
                if ((v && v !== "")) {
                    isCorrectAddress = true;
                } else {
                    isCorrectAddress = false;
                }
                break;
            case 'country':
                setCountry(v);
                if ((v && v !== "")) {
                    isCorrectCountry = true;
                } else {
                    isCorrectCountry = false;
                }
                break;
            case 'city':
                setCity(v);
                if ((v && v !== "")) {
                    isCorrectCity = true;
                } else {
                    isCorrectCity = false;
                }
                break;
            case 'state':
                setState(v);
                if ((v && v !== "")) {
                    isCorrectState = true;
                } else {
                    isCorrectState = false;
                }
                break;
            case 'zipCode':
                setZipCode(v);
                if ((v && v !== "")) {
                    isCorrectZipCode = true;
                } else {
                    isCorrectZipCode = false;
                }
                break;
            case 'clientDetails':
                setClientDetails(v);
                break;
            default:
                console.log('hmm..?');
        }

        setIsCorrectForm(isCorrectFirstName && isCorrectLastName && isCorrectEmail 
            && isCorrectPhone && isCorrectAddress && isCorrectCountry && 
            isCorrectCity && isCorrectState && isCorrectZipCode
        );
    }

    const checkout = (
        <div class="row px-xl-5">
            <div class="col-lg-8">
                <div class="mb-4">
                    <h4 class="font-weight-semi-bold mb-4">Adresă</h4>
                    <div class="row">
                        <div class="col-md-6 form-group">
                            <label>Prenume</label>
                            <input class="form-control" type="text" placeholder="Ion" value={firstName} onChange={e => setFormValue("firstName", e.target.value)}/>
                        </div>
                        <div class="col-md-6 form-group">
                            <label>Nume</label>
                            <input class="form-control" type="text" placeholder="Popescu" value={lastName} onChange={e => setFormValue("lastName", e.target.value)}/>
                        </div>
                        <div class="col-md-6 form-group">
                            <label>E-mail</label>
                            <input class="form-control" type="text" placeholder="example@email.com" value={email} onChange={e => setFormValue("email", e.target.value)}/>
                        </div>
                        <div class="col-md-6 form-group">
                            <label>Telefon</label>
                            <input class="form-control" type="text" placeholder="+123 456 789" value={phone} onChange={e => setFormValue("phone", e.target.value)}/>
                        </div>
                        <div class="col-md-6 form-group">
                            <label>Adresă</label>
                            <input class="form-control" type="text" placeholder="123 Street" value={address} onChange={e => setFormValue("address", e.target.value)}/>
                        </div>
                        <div class="col-md-6 form-group">
                            <label>Țară</label>
                            <input class="form-control" disabled type="text" value={country} onChange={e => setFormValue("country", e.target.value)}/>
                        </div>
                        <div class="col-md-6 form-group">
                            <label>Oraș</label>
                            <input class="form-control" type="text" placeholder="Bucuresti" value={city} onChange={e => setFormValue("city", e.target.value)}/>
                        </div>
                        <div class="col-md-6 form-group">
                            <label>Județ</label>
                            <input class="form-control" type="text" placeholder="Bucuresti" value={state} onChange={e => setFormValue("state", e.target.value)}/>
                        </div>
                        <div class="col-md-6 form-group">
                            <label>Cod poștal</label>
                            <input class="form-control" type="text" placeholder="123" value={zipCode} onChange={e => setFormValue("zipCode", e.target.value)}/>
                        </div>
                    </div>
                    <div class="row"><div class="col-md-12 form-group">
                        <label>Detalii comandă</label>
                        <input class="form-control" type="text" placeholder="Detalii pe care le puteți transmite echipei TGV Service." value={clientDetails} onChange={e => setFormValue("clientDetails", e.target.value)}/>
                    </div></div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="card border-secondary mb-5">
                    <div class="card-header bg-secondary border-0">
                        <h4 class="font-weight-semi-bold m-0">Comanda</h4>
                    </div>
                    <div class="card-body">
                        <h5 class="font-weight-medium mb-3">Produse</h5>
                        {cart.map((product, idx) => (
                            <div class="d-flex justify-content-between">
                                <p>{product.product.name}</p>
                                <p>{product.product.price} RON</p>
                            </div>
                        ))}
                        <hr class="mt-0"/>
                        <div class="d-flex justify-content-between mb-3 pt-1">
                            <h6 class="font-weight-medium">Subtotal</h6>
                            <h6 class="font-weight-medium">{cart.total} RON</h6>
                        </div>
                        <div class="d-flex justify-content-between">
                            <h6 class="font-weight-medium">Transport</h6>
                            <h6 class="font-weight-medium">Depinde de locația clientului</h6>
                        </div>
                    </div>
                    <div class="card-footer border-secondary bg-transparent">
                        <div class="d-flex justify-content-between mt-2">
                            <h5 class="font-weight-bold">Total</h5>
                            <h5 class="font-weight-bold">Subtotal + transport</h5>
                        </div>
                    </div>
                </div>
                <div class="card border-secondary mb-5">
                    <div class="card-header bg-secondary border-0">
                        {/* <ReCAPTCHA
                            sitekey="6LcfwgskAAAAALiaO_QN86OU_y9-mlDBgyf-UPw1"
                            onChange={e => setFormValue("captcha", e.target.value)}
                        /> */}
                        <label>Curier</label>
                        <DropdownList
                            value = {courier}
                            onChange={courier => setCourier(courier)}
                            defaultValue="AltaModalitateDeTransport"
                            data={["AltaModalitateDeTransport", 
                                "PostaRomanaPriorityPost",
                                "Sameday",
                                "Cargus",
                                "FanCourier"]}
                        />
                    </div>
                    <div class="card-footer border-secondary bg-transparent">
                        <button disabled={(isCorrectForm) ? false: true} class="btn btn-lg btn-block btn-primary font-weight-bold my-3 py-3" onClick={() => placeOrder()}>Plasează comanda</button>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div class="container-fluid pt-5">
            { checkoutProgress ? <LoadingSpinner /> : checkout }
        </div>
    )
}

export default Checkout;