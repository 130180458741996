import React, {useParams, useCallback, useState, useEffect } from 'react';
import env from "react-dotenv";

const axios = require('axios');

const BE = env.API_ENDPOINT;

const somePaddingStyle= {"padding-top": "1rem","padding-bottom": "1rem"};

function Contact() {
  const[name,setName]=useState();
  const[email,setEmail]=useState();
  const[subject,setSubject]=useState();
  const[body,setBody]=useState();
  const[submitMessage,setSubmitMessage]=useState();

  const handleSubmit= (e) => {
    console.log('handlesubmit');
    axios.post(
        BE + '/api/mail/contact', 
        {
            name: name,
            email: email,
            subject: subject,
            body: body
        }
    ).then(setSubmitMessage("Mail-ul a fost trimis cu succes. Vă vom contacta cu un răspuns în scurt timp.")).catch(function (error) {
        console.log(error);
        setSubmitMessage("S-a produs o eroare la trimiterea email-ului.");
    });

    e.preventDefault();
  }
    return (
        
<div className="container-fluid pt-5">
        <div className="text-center mb-4">
            <h2 className="px-5"><span className="px-2">Contact pentru orice întrebări</span></h2>
        </div>
        <div className="row px-xl-5">
            <div className="col-lg-7 mb-5">
                <div className="contact-form">
                    <div id="success"></div>
                    <form onSubmit={handleSubmit} name="sentMessage" novalidate="novalidate" style={somePaddingStyle}>
                        <div className="control-group">
                            <input type="text" value={name} onChange={e => setName(e.target.value)} className="form-control" id="name" placeholder="Nume"
                                required="required" data-validation-required-message="Please enter your name" />
                            <p className="help-block text-danger"></p>
                        </div>
                        <div className="control-group">
                            <input type="email" value={email} onChange={e => setEmail(e.target.value)} className="form-control" id="email" placeholder="Email"
                                required="required" data-validation-required-message="Please enter your email" />
                            <p className="help-block text-danger"></p>
                        </div>
                        <div className="control-group">
                            <input type="text" value={subject} onChange={e => setSubject(e.target.value)} className="form-control" id="subject" placeholder="Subiect"
                                required="required" data-validation-required-message="Please enter a subject" />
                            <p className="help-block text-danger"></p>
                        </div>
                        <div className="control-group">
                            <textarea className="form-control"value={body} onChange={e => setBody(e.target.value)} rows="6" id="message" placeholder="Mesaj"
                                required="required"
                                data-validation-required-message="Please enter your message"></textarea>
                            <p className="help-block text-danger"></p>
                        </div>
                        <div>
                            <button className="btn btn-primary py-2 px-4" type="submit" value="Submit">Trimite</button>
                            <p>{submitMessage}</p>
                        </div>
                    </form>
                </div>
            </div>
            <div className="col-lg-5 mb-5">
                <p>TGV Service LED-LCD S.R.L. vă pune la dispoziție o gamă variată de componente electronice</p>
                <div class="d-flex flex-column mb-3">
                    <p className="mb-2"><i className="fa fa-map-marker-alt text-primary mr-3"></i>Sat Frumuşani, Comuna Frumuşani, Strada principală, Nr. 53, Județ Călărași</p>
                    <p className="mb-2"><i className="fa fa-envelope text-primary mr-3"></i>piese.tv@yahoo.com</p>
                    <p className="mb-2"><i className="fa fa-envelope text-primary mr-3"></i>stroe.georgiana93@gmail.com</p>
                    <p className="mb-2"><i className="fa fa-phone-alt text-primary mr-3"></i>+0729532485</p>
                </div>
            </div>
        </div>
    </div>

    );
}

export default Contact;

