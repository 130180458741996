import React, { useState, useEffect } from 'react';
import env from "react-dotenv";
import {useNavigate} from 'react-router-dom';

const BE = env.API_ENDPOINT;

function Cart({ cart, setCart }) {

    const[totalSum, setTotalSum] = useState(0);
    const navigate = useNavigate();

    console.log(cart);

    const getTotalSum = () => {
      console.log(cart);

      setTotalSum(cart.reduce((sum, { product, quantity }) => sum + product.price * quantity, 0 ));
    };

    useEffect(() => {
        getTotalSum();
        
    }, [cart]);

    const removeFromCart = (productToRemove) => {
        console.log(productToRemove);
      setCart(
        cart.filter((product) => product !== productToRemove)
      );
    };

    const onChangeHandler = () => {}

    const handlePlusAmount = (product) => {

        console.log("plus");

        const newCart = [...cart];

        const a = newCart.find(
            (item) => item.name === product.name
            ).quantity;

        if (product.product.inventory < a + 1) {
            // Raise react alert
        } else {
            newCart.find((item) => item.name === product.name).quantity = a + 1;
        }
        setCart(newCart);
    }

    const handleMinusAmount = (product) => {

        console.log("minus");
        const newCart = [...cart];

        const a = newCart.find(
            (item) => item.name === product.name
            ).quantity;

        if (a - 1 > 0) {
            newCart.find((item) => item.name === product.name).quantity = a - 1;
        }
        setCart(newCart);
    }

    const goToCheckout = () => {
        const newCart = [...cart];
        newCart.total = totalSum;
        setCart(cart => newCart);
        navigate(`/checkout`);
    }
  
    return (
        <div className="container-fluid pt-5 products">
            <div className="row px-xl-5">
                <div className="col-lg-8 table-responsive mb-5">
                    <table className="table table-bordered text-center mb-0">
                        <thead className="bg-secondary text-dark">
                            <tr>
                                <th>Produs</th>
                                <th>Preț</th>
                                <th>Cantitate</th>
                                <th>Total</th>
                                <th>Șterge</th>
                            </tr>
                        </thead>
                        <tbody class="align-middle">
                        {cart.map((product, idx) => (
                            <tr className="product" key={idx}>    
                                <td className="align-middle">
                                    <img  alt="" src={product.src} style={{"width": "100px","padding":"5px"}}>
                                    </img> 
                                    {product.product.name}
                                </td>
                                <td className="align-middle">{product.product.price} RON</td>
                                <td className="align-middle">
                                        <div className="input-group quantity mx-auto" style={{"width": "100px"}}>
                                        <div className="input-group-btn">
                                            <button className="btn btn-sm btn-primary btn-minus" onClick={() => handleMinusAmount(product)}>
                                                <i className="fa fa-minus"></i>
                                            </button>
                                        </div>
                                        <input type="text" className="form-control form-control-sm bg-secondary text-center" 
                                        value={product.quantity}
                                        onChange={() => onChangeHandler}>

                                        </input>
                                        <div className="input-group-btn">
                                            <button className="btn btn-sm btn-primary btn-plus" onClick={() => handlePlusAmount(product)}>
                                                <i className="fa fa-plus"></i>
                                            </button>
                                        </div>
                                    </div> 
                                </td>
                                <td className="align-middle">{product.product.price * product.quantity} RON</td>
                                <td className="align-middle">
                                    <button onClick={() => removeFromCart(product)}className="btn btn-sm btn-primary">
                                        <i className="fa fa-times"></i>
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                <div class="col-lg-4">
                    <div class="card border-secondary mb-5">
                        <div class="card-header bg-secondary border-0">
                            <h4 class="font-weight-semi-bold m-0">Sumar</h4>
                        </div>
                        <div class="card-body">
                            <div class="d-flex justify-content-between mb-3 pt-1">
                                <h6 class="font-weight-medium">Subtotal</h6>
                                <h6 class="font-weight-medium">{totalSum} RON</h6>
                            </div>
                            <div class="d-flex justify-content-between">
                                <h6 class="font-weight-medium">Transport</h6>
                                <h6 class="font-weight-medium">Depinde de locația clientului</h6>
                            </div>
                        </div>
                        <div class="card-footer border-secondary bg-transparent">
                            <div class="d-flex justify-content-between mt-2">
                                <h5 class="font-weight-bold">Total</h5>
                                <h5 class="font-weight-bold">Subtotal + Transport</h5>
                            </div>
                            <button disabled={(cart.length > 0) ? false: true} class="btn btn-block btn-primary my-3 py-3" onClick={()=>goToCheckout()}>Finalizează comanda</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )};

export default Cart;