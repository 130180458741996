import { findByLabelText } from '@testing-library/react';
import { Link } from 'react-router-dom';

const cartSize = {"font-size": "1.5rem"};

function Nav({ setCart, cart }) {

    const getCartTotal = () => {
        return cart.reduce(
          (sum, { quantity }) => sum + quantity,
          0
        );
    };

    return (
        <div className="container-fluid mb-5 bg-important-1">
            <div className="row align-items-center py-3 px-xl-5 bg-important-1">
                <div className="col-lg-3 d-none d-lg-block">
                    <div className='inverted-3'>
                        <a href="" className="text-decoration-none" >
                            <h2 className="m-0 display-5 font-weight-semi-bold"><span className="text-primary font-weight-bold px-3 mr-1 ">TGV Service LED-LCD</span></h2>
                        </a>
                    </div>
                </div>
                <div className="col-lg-6 col-6 text-left">
                </div>
                <div className="col-lg-3 col-6 text-right">
                    <Link className="btn-cart" to='/cart'>
                        <i className="fas fa-shopping-cart text-primary" style={cartSize}></i>
                        <span className="badge" style={cartSize}>{getCartTotal()}</span>
                    </Link>
                </div>
            </div>
            <div className="row row-nav border-top px-xl-5">
                <div className="col-lg-12">
                    <nav className="navbar navbar-expand-lg bg-important-1 navbar-light py-3 py-lg-0 px-0">
                    <a href="" class="text-decoration-none d-block d-lg-none">
                    <div className='inverted-3'>TGV Service LED-LCD</div>
                    </a>
                    <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                        <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                            <div className="navbar-nav mr-auto py-0">
                                <li className="nav-item nav-link"><Link className='nav-text' to='/'>Acasă</Link></li>
                                <li className="nav-item nav-link"><Link className='nav-text' to='/shop'>Magazin</Link></li>
                                <li className="nav-item nav-link"><Link className='nav-text' to='/info'>Informații</Link></li>
                                <li className="nav-item nav-link"><Link className='nav-text' to='/contact'>Contact</Link></li>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    )
}

export default Nav;