import React, {useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import pic from '../NoImageAvailable.png'
import env from "react-dotenv";

const BE = env.API_ENDPOINT;
const STORE = env.STORE_ENDPOINT;

function Detail({ setCart, cart }) {
    const { id } = useParams();

    useEffect(() => {
        fetchItem();
        
    }, []);

    const addToCart = (product) => {

        console.log("addToCart");
        let newCart = [...cart];
        console.log("product.product.name" + product.product.name);
        console.log("item.name" + item.product.name);
        let itemInCart = newCart.find(
            (item) => product.product.name === item.product.name
        );

        console.log(itemInCart);
        console.log("amount: " + amount);
        

        if (itemInCart != undefined && itemInCart != null) {

            console.log("product.product.inventory: " + product.product.inventory);
            console.log("itemInCart.quantity: " + itemInCart.quantity);

            if(itemInCart.quantity + amount > product.product.inventory) {
                // Raise react alert
                
            } else {
                itemInCart.quantity+=amount;
            }
        } else {
            console.log("new item in cart");
            if (amount > product.product.inventory) {
                // Raise react alert
            } else {
                itemInCart = {
                ...product,
                quantity: amount,
                }
                newCart.push(itemInCart);
            }
        }
        setCart(newCart);
    };

    const [item, setItem] = useState({});
    const [product, setProduct] = useState({});
    const [compatibleModels, setCompatibleModels] = useState([]);
    const [amount, setAmount] = useState(1);

    const fetchItem = async () => {
        console.log('fetching item...');
        
        console.log(id);
        const data = await fetch (
            BE + `/api/product/${id}`
        );

        const item = await data.json();
        console.log(item);

        setProduct(p => item.product);
        setCompatibleModels(cm => item.compatibleModels);

        if(item.product.images == undefined || item.product.images[0] == undefined) {
            item.src = pic;
        }
        else {
            item.src = STORE + '/store/download/' + item.product.images[0].id;
            console.log(item.src);
        }
        setItem(item);
    }

    const handlePlusAmount = () => {

        console.log("plus");

        let actualAmount = amount + 1;

        let itemInCart = cart.find(
            (item) => product.name === item.name
        );

        if (itemInCart) {
            actualAmount = itemInCart.quantity;
        } 

        if (product.inventory < actualAmount) {
            // Raise react alert
            console.log("Raise react alert");
        } else {
            setAmount(am => amount + 1);
        }
    }

    const handleMinusAmount = () => {

        console.log("minus");

        if (amount - 1 > 0) {
            setAmount(am => amount - 1);
        }

    }

    const handleChange = () => {
        console.log("change");
        // Do nothing, the input is modifiable via plus and minus
    }

    const imgStyle = { 'object-fit': 'cover', 'height': '400px'}

    return (
        <div>
            <div key={product.id} className="container-fluid py-5">
                <div className="row px-xl-5">
                    <div className="col-lg-5 pb-5">
                        <div id="product-carousel" className="carousel slide" data-ride="carousel">
                            <div className="carousel-inner border">
                                <div className="carousel-item active">
                                    <img className="w-100 h-100" src={item.src}/>    
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-7 pb-5">
                        <h3 className="font-weight-semi-bold">{product.name}</h3>
                        <h6 className="text-truncate mb-3">{product.inventory < 1 ? "Out of stock" : ""}</h6>
                        
                        <h3 className="font-weight-semi-bold mb-4">{product.price} RON</h3>
                        <p className="mb-4">{product.brand}</p>
                        
                        <div className="d-flex align-items-center mb-4 pt-2">
                            <div className="input-group quantity mr-3" style={{'width': '130px'}}>
                                <div className="input-group-btn">
                                    <button className="btn btn-primary btn-minus" onClick={() => handleMinusAmount()}>
                                        <i className="fa fa-minus"></i>
                                    </button>
                                </div>
                                <input type="text" className="form-control bg-secondary text-center" value={amount} onChange={() => handleChange()}>
                                </input>
                                <div className="input-group-btn">
                                    <button className="btn btn-primary btn-plus" onClick={() => handlePlusAmount()}>
                                        <i className="fa fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                            <button className="btn btn-primary px-3" onClick={() => addToCart(item)}><i className="fa fa-shopping-cart mr-1"></i> Add To Cart</button>
                        </div>
                    </div>
                </div>
                <div className="row px-xl-5">
                    <div className="col">
                        <div className="nav nav-tabs justify-content-center border-secondary mb-4">
                            <a className="nav-item nav-link active" data-toggle="tab" href="#tab-pane-1">Descriere</a>
                            <a className="nav-item nav-link" data-toggle="tab" href="#tab-pane-2">Compatibilitate</a>
                        </div>
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="tab-pane-1">
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item px-0">
                                        <b>Descriere</b>: {product.description}
                                    </li>
                                    <li className="list-group-item px-0">
                                        <b>Stare</b>: {product.condition}
                                    </li>
                                    <li className="list-group-item px-0">
                                        <b>Status</b>: {product.status}
                                    </li>
                                    <li className="list-group-item px-0">
                                        <b>Testat</b>: {product.tested ? "Da" : "Nu"}
                                    </li>
                                </ul>
                            </div>
                            <div className="tab-pane fade" id="tab-pane-2">
                                <div className="row">
                                    <div className="col-md-12">
                                        <ul className="list-group list-group-flush">
                                            {compatibleModels.map(cm => (
                                            <li className="list-group-item px-0">
                                                {cm.name}
                                            </li>
                                            ))}
                                        </ul>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    );
}

export default Detail;