import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import pic from '../NoImageAvailable.png';
import LoadingSpinner from "../components/loadingspinner";
import env from "react-dotenv";

const axios = require('axios');

const BE = env.API_ENDPOINT;
const STORE = env.STORE_ENDPOINT;

console.log(BE);
function Home({ setCart, cart }) {

    const [items, setItems] = useState([]);
    const [renderedItems, setRenderedItems] = useState([]);

    const [isLoadingProducts, setIsLoadingProducts] = useState(false);
    const [isLoadingCategories, setIsLoadingCategories] = useState(false);


    useEffect(() => {

        const fetchItems = async () => {
            setIsLoadingCategories(true);
            console.log( BE + '/api/category/images/all');
            const data = await fetch(
                BE + '/api/category/all/images'
            );

            const response = await data.json();
            response.forEach(element => {
                if (element.image != undefined) {
                    console.log(element.image);
                    element.src = STORE + '/store/download/' + element.image + "?resolution=Low";
                    console.log(element.src);
                } else {
                    element.src = pic;
                }
            });

            setItems(response);
        }

        fetchItems().then(() => { setIsLoadingCategories(false) }).catch(console.error);;

    }, []);

    const imgStyle = { 'object-fit': 'cover', 'height': '200px' }
    const paddingStyle = { 'padding': '30px' }

    useEffect(() => {
        const fetchItems = async () => {
            setIsLoadingProducts(true);
            const data = await fetch(
                BE + '/api/product/all/latest/6'
            );

            const response = await data.json();

            response.forEach(element => {
                if (element.images != undefined && element.images[0] != undefined) {
                    element.src = STORE + '/store/download/' + element.images[0].id + "?resolution=Low";
                    console.log(element.src);
                } else {
                    element.src = pic;
                }
            });

            setRenderedItems(x => response);
            console.log(renderedItems);
        }

        fetchItems().then(() => { setIsLoadingProducts(false); }).catch(console.error);;
    }, []);

    const addToCart = (product) => {
        let newCart = [...cart];
        product.product = product;
        let itemInCart = newCart.find(
            (item) => product.name === item.name
        );
        if (itemInCart) {
            if(itemInCart.quantity + 1 > product.inventory) {
                // Raise react alert
            } else {
                itemInCart.quantity++;
            }
        } else {
            if (1 > product.inventory) {
                // Raise react alert
                // This is the out of stock case
            } else {
                itemInCart = {
                ...product,
                quantity: 1,
            }
        };
            newCart.push(itemInCart);
        }
        setCart(newCart);
    };

    const categories = (
        <div className="row px-xl-5 pb-3">
            {items.filter(item => item.category.catalogueList.length > 0).map(item => (
                <div className="col-lg-4 col-md-6 pb-1">
                    <div className="cat-item d-flex flex-column border mb-4" style={paddingStyle}>
                        <p className="text-right">{item.category.catalogueList.length}</p>
                        {/* <a href="" > */}
                            <Link className="cat-img position-relative overflow-hidden mb-3" to={`/shop?category=${item.category.id}`}>
                                <img className="img-fluid" style={imgStyle} src={item.src} alt="">
                                </img>
                            </Link>
                        {/* </a> */}
                        <h5 className="font-weight-semi-bold m-0">{item.category.name}</h5>
                    </div>
                </div>
            ))}
        </div>
    );

    const products = (
        <div className="row px-xl-5 pb-3">
            {renderedItems.map(item => (
                <div className="col-lg-4 col-md-6 col-sm-12 pb-1">
                    <div className="card product-item border-0 mb-4">
                        <div className="card-header product-img position-relative overflow-hidden bg-transparent border p-0">
                            <img className="img-fluid w-100" style={imgStyle} src={item.src} alt="" />
                        </div>
                        <div className="card-body border-left border-right text-center p-0 pt-4 pb-3">
                            <h6 className="text-truncate mb-3">{item.name}</h6>
                            <h6 className="text-truncate mb-3">{item.inventory < 1 ? "Out of stock" : ""}</h6>
                            <div className="d-flex justify-content-center">
                                <h6>{item.price} RON</h6>
                            </div>
                            
                        </div>
                        <div className="card-footer d-flex justify-content-between bg-important-1 border">
                            <a className="btn btn-sm text-dark p-0"><i key={item.id} className="fas fa-eye text-primary mr-1"></i><Link style={{ 'color': 'inherit', 'text-decoration': 'inherit' }} to={`/detail/${item.id}`}>Vezi detalii</Link></a>
                            <button className="btn btn-sm text-dark p-0" onClick={() => addToCart(item)}><i className="fas fa-shopping-cart text-primary mr-1"></i>Adaugă în coș</button>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );

    return (

        <div>
            <div className="container-fluid pt-5">
                <div className="text-center mb-4">
                    <h2 className="px-5"><span className="px-2">Categorii</span></h2>
                </div>
                {isLoadingCategories ? <LoadingSpinner /> : categories}
            </div>
            <div className="container-fluid pt-5">
                <div className="row px-xl-5 pb-3">
                    <div className="col-lg-3 col-md-6 col-sm-12 pb-1">
                        <div className="d-flex align-items-center border mb-4" style={paddingStyle}>
                            <h1 className="fa fa-check text-primary m-0 mr-3"></h1>
                            <h5 className="font-weight-semi-bold m-0">Produse verificate</h5>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 pb-1">
                        <div className="d-flex align-items-center border mb-4" style={paddingStyle}>
                            <h1 className="fa fa-shipping-fast text-primary m-0 mr-2"></h1>
                            <h5 className="font-weight-semi-bold m-0">Transport rapid</h5>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 pb-1">
                        <div className="d-flex align-items-center border mb-4" style={paddingStyle}>
                            <h1 className="fas fa-exchange-alt text-primary m-0 mr-3"></h1>
                            <h5 className="font-weight-semi-bold m-0">Politica de retur</h5>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 pb-1">
                        <div className="d-flex align-items-center border mb-4" style={paddingStyle}>
                            <h1 className="fa fa-phone-volume text-primary m-0 mr-3"></h1>
                            <h5 className="font-weight-semi-bold m-0">Asistență</h5>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid pt-5">
                <div className="text-center mb-4">
                    <h2 className="px-5"><span className="px-2">Ultimele produse</span></h2>
                </div>
                {isLoadingProducts ? <LoadingSpinner /> : products}
            </div>
        </div>

    );


}

export default Home;