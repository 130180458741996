import React, { useState} from 'react'
import Nav from './components/navigation';
import Home from './pages/home';
import Shop from './pages/shop';
import Info from './pages/info';
import Contact from './pages/contact';
import Detail from './pages/detail';
import Footer from './components/footer';
import Cart from './pages/cart';
import Checkout from './pages/checkout';
import Tracking from './pages/tracking';
import { HashRouter as Router, Routes, Route } from 'react-router-dom'

function App() {

  const [cart, setCart] = useState([]);

  return (
    <Router>
      <div>
        <Nav cart={cart} setCart={setCart} />
        <Routes>
          <Route path="/" element={<Home cart={cart} setCart={setCart}/>} />
          <Route path="/shop" element={<Shop cart={cart} setCart={setCart} />} />
          <Route path="/info" element={<Info />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/detail/:id" element={<Detail cart={cart} setCart={setCart}/>} />
          <Route path="/cart" element={<Cart cart={cart} setCart={setCart} />} />
          <Route path="/checkout" element={<Checkout cart={cart} setCart={setCart} />} />
          <Route path="/tracking/:id" element={<Tracking/>} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
