import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

function Footer() {

  return (
    <div className="container-fluid bg-secondary text-dark mt-5 pt-5">
        <div className="row px-xl-5 pt-5">
            <div className="col-lg-4 col-md-12 mb-5 pr-3 pr-xl-5">
                <a href="" className="text-decoration-none">
                    <h1 className="display-5 font-weight-semi-bold"><span className="text-primary font-weight-bold px-3 mr-1 inverted-logo">TGV Service LED-LCD</span></h1>
                </a>
                <p>TGV Service LED-LCD S.R.L. vă pune la dispoziție o gamă variată de componente electronice.</p>
                <p className="mb-2"><i className="fa fa-map-marker-alt text-primary mr-3"></i>Sat Frumuşani, Comuna Frumuşani, Strada principală, Nr. 53, Județ Călărași</p>
                <p className="mb-2"><i className="fa fa-envelope text-primary mr-3"></i>piese.tv@yahoo.com</p>
                <p className="mb-2"><i className="fa fa-envelope text-primary mr-3"></i>stroe.georgiana93@gmail.com</p>
                <p className="mb-0"><i className="fa fa-phone-alt text-primary mr-3"></i>+0729532485</p>
            </div>
            <div className="col-lg-8 col-md-12">
                <div className="row">
                    <div className="col-md-4 mb-5">
                        <h4 className="font-weight-bold text-dark">Link-uri</h4>
                        <div className="d-flex flex-column justify-content-start">
                            <Link to="/" className="text-dark mb-2"> <i className="fa fa-angle-right mr-2"></i>Pagina principală</Link>
                            <Link to="/shop" className="text-dark mb-2"><i className="fa fa-angle-right mr-2"></i>Magazin</Link>
                            <Link to="/info" className="text-dark mb-2"><i className="fa fa-angle-right mr-2"></i>Info</Link>
                            <Link to="/contact" className="text-dark"><i className="fa fa-angle-right mr-2"></i>Contact</Link>
                        </div>
                    </div>
                    <div className="col-md-4 mb-5">
                        <div className="d-flex flex-column justify-content-start">
                            <div class="container">
                                <h4>Link-uri ANPC</h4>
                                <a style={{ 'font-weight': 'bold', 'font-size': 'large', 'color':'white' }} href="https://anpc.ro/" target="_blank">ANPC</a> 
                                <br/>
                                <a style={{ 'font-weight': 'bold', 'font-size': 'large', 'color':'white' }} href="https://anpc.ro/ce-este-sal/" target="_blank">ANPC - SAL</a> 
                                <br/>
                                <a style={{ 'font-weight': 'bold', 'font-size': 'large', 'color':'white' }} href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=RO" target="_blank">SOL</a>
                                <br/>
                                <a href="https://anpc.ro/ce-este-sal/" target="_blank">
                                    <img src="https://www.bareteledtv.ro/img/anpc-sal.png" width="100px"/>
                                </a>
                                <a href="https://ec.europa.eu/consumers/odr/" target="_blank">
                                    <img src="https://www.bareteledtv.ro/img/sol.png" width="100px"/>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-5">
                        {/* <h5 className="font-weight-bold text-dark">Buletin informativ</h5>
                        <form action="">
                            <div className="form-group">
                                <input type="text" className="form-control border-0 py-4" placeholder="Nume" required="required" />
                            </div>
                            <div className="form-group">
                                <input type="email" className="form-control border-0 py-4" placeholder="Email"
                                    required="required" />
                            </div>
                            <div>
                                <button className="btn btn-primary btn-block border-0 py-3" type="submit">Inscrie-te acum</button>
                            </div>
                        </form> */}
                    </div>
                </div>
            </div>
        </div>
        <div className="row border-top border-light mx-xl-5 py-4">
            <div className="col-md-6 px-xl-0">
                <p className="mb-md-0 text-center text-md-left text-dark">
                    &copy; <a className="text-dark font-weight-semi-bold" href="#">TGV Service LED-LCD</a>. CUI: 43093330.
                </p>
            </div>
            <div className="col-md-6 px-xl-0 text-center text-md-right">
                <img className="img-fluid" src="img/payments.png" alt=""></img>
            </div>
        </div>
    </div>
  )
}

export default Footer;