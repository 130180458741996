import React, {useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import pic from '../NoImageAvailable.png';
import { useSearchParams } from 'react-router-dom';
import LoadingSpinner from "../components/loadingspinner";
import env from "react-dotenv";

const axios = require('axios');

const BE = env.API_ENDPOINT;
const STORE = env.STORE_ENDPOINT;

// TODO: pagination style to render it centrally
const navStyle = {"overflowX": "scroll","align-self":"center"};
const inputStyle = {"width": "100%"}

function Shop({ setCart, cart }) {

    const [searchParams, setSearchParams] = useSearchParams();
    const [textFilter, setTextFilter] = useState(undefined);
    const [categoriesFilter, setCategoriesFilter] = useState(undefined);
    const [brandsFilter, setBrandsFilter] = useState(undefined);
    const [items, setItems] = useState([]);
    const [renderedItems, setRenderedItems] = useState([]);
    const [pageInfo, setPageInfo] = useState({});
    const [renderedCategories, setRenderedCategories] = useState([]);
    const [renderedBrands, setRenderedBrands] = useState([]);
    const [otherBrands, setOtherBrands] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [isLoadingProducts, setIsLoadingProducts] = useState(false);
    const [isLoadingCategories, setIsLoadingCategories] = useState(false);
    const [isLoadingBrands, setIsLoadingBrands] = useState(false);
    const [initialFilterCategory, setInitialFilterCategory] = useState("");

    const addToCart = (product) => {
        let newCart = [...cart];
        let itemInCart = newCart.find(
            (item) => product.product.name === item.product.name
        );
        if (itemInCart) {
            if(itemInCart.quantity + 1 > product.product.inventory) {
                // Raise react alert
            } else {
                itemInCart.quantity++;
            }
        } else {
            if (1 > product.product.inventory) {
                // Raise react alert
                // This is the out of stock case
            } else {
                itemInCart = {
                ...product,
                quantity: 1,
                }
                newCart.push(itemInCart);
            };
        }
        setCart(newCart);
    };

    const fetchItems = async (i) => {
        if (i == undefined ) {
            i = 0;
        }
        console.log("Page:" + i);
        setCurrentPage( i );
        console.log("Page:" + currentPage);
        console.log(textFilter);
        console.log(categoriesFilter);
        console.log("brandsFilter: "+ brandsFilter);

        let catParam = "";
        let brandParam = "";
        
        try{
            categoriesFilter.forEach( (cat) => {
                catParam += cat + ",";
            });
        } catch (e) {
            console.log(e);
        }

        try{
            brandsFilter.forEach( (b) => {
                brandParam += b + ",";
            });
        } catch (e) {
            console.log(e);
        }

        catParam = catParam.substring(0, catParam.length -1);
        brandParam = brandParam.substring(0, brandParam.length -1);

        console.log("catParam:" + catParam);

        let endpoint = '/api/product/all/with/models?size=27&page=' + i + '&category=' + catParam + '&brands=' + brandParam;

        if (textFilter !== "" && textFilter !== undefined) {
            endpoint += '&name=' + textFilter
        } else {
            endpoint += '&name='
        }

        console.log("-->" + endpoint)

        setIsLoadingProducts(true);
        const data = await fetch (
            BE + endpoint
        );

        let items = await data.json();

        let pageInfo = {};

        pageInfo["totalPages"]=items.totalPages;
        console.log(pageInfo.totalPages);
        pageInfo["totalElements"]=items.totalElements;
        pageInfo["page"]=items.page;
        pageInfo["size"]=items.size;

        setItems(items);
        setPageInfo(pageInfo);

        items.products.forEach(element => {
            if(element.product.images != undefined && element.product.images[0] != undefined) {
                element.src = STORE + '/store/download/' + element.product.images[0].id + "?resolution=Low";
                console.log(element.src);
            } else {
                element.src = pic;
            }
        });

        setRenderedItems(x => items.products);

        console.log("--> renderedItems <--");
        console.log(renderedItems);
        console.log(items);
        window.scrollTo(0, 0);
    }

    const fetchBrands = async () => {
        console.log("useEffect init brands");
        let auxBrands = undefined;

        setIsLoadingBrands(true);
        const dat1 = await fetch (
            BE + '/api/brand/all'
        );
        const resp1 = await dat1.json();
        let auxOthers = [];
        let auxResult = [];
        let auxOthersCatalogueList = [];

        resp1.forEach((b) => {
            if (b.brandCatalogueList.length > 15 && b.name != 'Altele') {
                console.log(b);
                console.log("normal list");
                auxResult.push(b);
            } else {
                console.log(b);
                console.log("other list");
                auxOthersCatalogueList = [...auxOthersCatalogueList, b.brandCatalogueList];
                auxOthers.push(b.id);
            }
        })

        let otherBrand = {name: "Altele", id: "other_id", "brandCatalogueList": auxOthersCatalogueList};
        auxResult.push(otherBrand);
        setRenderedBrands(c => auxResult);
        setOtherBrands(c => auxOthers);

        console.log("fetching categories...");
        console.log(renderedBrands);
        console.log(resp1);
        setBrandsFilter([]);
    }

    useEffect(() => {
        fetchBrands().then(setIsLoadingBrands(false));
    }, []);

    useEffect(() => {
        const fetchCategories = async (_callback) => {
            console.log("useEffect init");
            let auxCats = undefined;

            setIsLoadingCategories(true);
            const dat1 = await fetch (
                BE + '/api/category/all'
            );
            const resp1 = await dat1.json();
            auxCats = resp1.filter(category=>category.catalogueList.length>0);
            setRenderedCategories(c => auxCats);
            console.log("fetching categories...");
            console.log(renderedCategories);
            console.log(resp1);

            try {
                console.log("then...")
                const cat = searchParams.get("category");
                setInitialFilterCategory(cat);
                console.log(cat);
                console.log("renderedCategories");
                console.log(renderedCategories);
                let renderedCategoriesIds = auxCats.map(item => item.id);
    
                if (cat != null && cat != undefined 
                    // && renderedCategoriesIds.includes(cat)
                    ) 
                    {
                    setCategoriesFilter(x => [cat]);
                    console.log("categoriesFilter A");
                } else {
                    setCategoriesFilter([]);
                    console.log("categoriesFilter B");
                }
            } catch (error) {
                console.log(error);
            }

            return auxCats;
        }

        fetchCategories().then((auxCats) => 
        {
            console.log("loaded categories.."); 
            setIsLoadingCategories(false);
            if ((auxCats !== undefined && auxCats.length != 0) && categoriesFilter !== undefined) {
            fetchItems()
                .then(() => { console.log("loaded products.."); setIsLoadingProducts(false)})
                .catch((e) => console.log("error fetching products: " + e))
            } else {
                console.log("hmm..");
            }
        }).catch(console.error);
        console.log('i fire once');
    }, []);

    useEffect(() => {
        if (textFilter != undefined) {
            console.log("Products trigger");
            fetchItems().then(()=>setIsLoadingProducts(false));
        }
    }, [textFilter]); 

    useEffect(() => {
        if (categoriesFilter !== undefined) {
            console.log("Categories trigger");
            fetchItems().then(()=>setIsLoadingProducts(false));
        }
    }, [categoriesFilter]); 

    useEffect(() => {
        if (brandsFilter !== undefined) {
            console.log("brands trigger");
            fetchItems().then(()=>setIsLoadingProducts(false));
        }
    }, [brandsFilter]); 

    const [total, setTotal] = useState(0);
    
    const imgStyle = { 'object-fit': 'cover', 'height': '200px'}

    const onChangeHandler = async (text) => {
        setTextFilter(text);
    }

    function removeElement(arr, value) { 
        let result = arr.filter(function(ele){ 
            return ele != value; 
        });
        return [...result];
    }

    /* Hmmm */
    const handleCheckBox = (e) => {
        console.log("handleCheckBox");

        if (e.target.checked) {
            console.log("e.target.id: " + e.target.id);
            setCategoriesFilter([...categoriesFilter, e.target.id]);
        } else {
            setCategoriesFilter(removeElement(categoriesFilter, e.target.id));
        }
        console.log(categoriesFilter);
    };

        /* Hmmm */
    const handleBrandCheckBox = (e) => {
            console.log("handleCheckBox");
    
            if (e.target.checked) {
                if (e.target.id == "other_id") {
                    setBrandsFilter([...brandsFilter, otherBrands]);
                } else {
                    console.log("e.target.id: " + e.target.id);
                    setBrandsFilter([...brandsFilter, e.target.id]);
                }
            } else {
                if (e.target.id == "other_id") {
                    otherBrands.forEach((b) => 
                        setBrandsFilter(removeElement(brandsFilter, b.id))
                    ); 
                } else {   
                    setBrandsFilter(removeElement(brandsFilter, e.target.id));
                }
            }
            console.log(brandsFilter);
    };

    function getLiClassName(i) {

        if (i == pageInfo.page) {
            return "page-item active"
        }

        return "page-item"
    }

    const navBar = (
        <div className="row pb-3">
            <div className="col-12 pb-1">
                <nav>
                    <ul style={navStyle} class="pagination pagination-lg">
                    {/* <ul class="pagination pagination-lg">
                        <li disabled= "true" class="page-item">
                            <a disabled= "true" class="page-link" href="#" tabindex={pageInfo.page}>Previous</a>
                        </li>
                    </ul> */}
                    <ul style={navStyle} class="pagination pagination-lg">
                        {[...Array(pageInfo.totalPages)].map((_,i) =>
                            <li className={getLiClassName(i)} active={i == pageInfo.page}>
                                <a className="page-link" onClick={() => fetchItems(i).then(()=>setIsLoadingProducts(false))}>{i}</a>
                            </li>
                        )}
                    </ul>
                    {/* <ul class="pagination pagination-lg">
                        <li disabled= "true" class="page-item">
                            <a disabled= "true" class="page-link" href="#">Next</a>
                        </li>
                    </ul> */}
                    </ul>
                    
                </nav>
            </div>
        </div>
    );

    const categories = (
        <form>
            {renderedCategories.map(category => (
                <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                    <input disabled={isLoadingProducts}
                        id={category.id}
                        value = {category.name}
                        type = "checkbox"
                        onChange={e => handleCheckBox(e)}
                        defaultChecked={category.id == initialFilterCategory ? true : false}>
                    </input>
                    <label for="price-all">{category.name}</label>
                    <span className="badge border font-weight-normal">{category.catalogueList.length}</span>
                </div>
            ))}
        </form>
    );

    const brands = (
        <form>
            {renderedBrands.map(brand => (
                <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                    <input disabled={isLoadingProducts}
                        id={brand.id}
                        value = {brand.name}
                        type = "checkbox"
                        onChange={e => handleBrandCheckBox(e)}
                        defaultChecked={false}>
                    </input>
                    <label for="price-all">{brand.name}</label>
                    <span className="badge border font-weight-normal">{brand.brandCatalogueList.length}</span>
                </div>
            ))}
        </form>
    );

    const textFilterDiv = (
        <div className="row pb-3">
            <div className="col-12 pb-1">
                <div >
                    <form action="">
                        <div  className="search">
                            <input type='text' 
                                    style={inputStyle}
                                    className="search-form"
                                    placeholder='Caută după produs, model compatibil sau marcă' 
                                    onChange={e => onChangeHandler(e.target.value)}>        
                            </input>
                        </div>
                    </form>
                    <div className="dropdown ml-4">
                    </div>
                </div>
            </div>
        </div>
    )

    const products = (
        <div className="row pb-3">
                {renderedItems.map(item => (
                    <div className="col-lg-4 col-md-6 col-sm-12 pb-1">
                        <div className="card product-item border-0 mb-4">
                            <div className="card-header product-img position-relative overflow-hidden bg-transparent border p-0">
                                <img className="img-fluid w-100" style={imgStyle} src={item.src} alt=""/>
                            </div>
                            <div className="card-body border-left border-right text-center p-0 pt-4 pb-3">
                                <h6 className="text-truncate mb-3">{item.product.name}</h6>
                                <h6 className="text-truncate mb-3">{item.product.inventory < 1 ? "Out of stock" : ""}</h6>
                                <div className="d-flex justify-content-center">
                                    <h6>{item.product.price} RON</h6> 
                                </div>
                            </div>
                            <div className="card-footer d-flex justify-content-between bg-important-1 border">
                                <a className="btn btn-sm text-dark p-0">
                                    <i key={item.product.id} className="fas fa-eye text-primary mr-1">
                                    </i>
                                    <Link style={{ 'color': 'inherit', 'text-decoration': 'inherit' }} to={`/detail/${item.product.id}`}>Vezi detalii</Link></a>
                                <a onClick={() => addToCart(item)} className="btn btn-sm text-dark p-0"><i className="fas fa-shopping-cart text-primary mr-1"></i>Adaugă în coș</a>
                            </div>
                        </div>
                    </div>
                ))}
        </div>
    );
    
    return (
        <div>
            <div className="container-fluid pt-5">    
                <div className="row px-xl-5">
                    <div className="col-lg-3 col-md-12">
                    </div>
                    <div className="col-lg-9 col-md-12">
                        {textFilterDiv}
                    </div>
                </div>
                <div className="row px-xl-5">
                    <div className="col-lg-3 col-md-12">
                        <div classNAme="row">
                            <div className="border-bottom mb-4 pb-4">
                                <h5 className="font-weight-semi-bold mb-4">Categorii de produse</h5>
                                { isLoadingCategories ? <LoadingSpinner /> : categories }
                            </div>
                        </div>
                        <div classNAme="row">
                            <div className="border-bottom mb-4 pb-4">
                                <h5 className="font-weight-semi-bold mb-4">Mărci</h5>
                                { isLoadingBrands ? <LoadingSpinner /> : brands }
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-9 col-md-12">
                        { isLoadingProducts ? <LoadingSpinner /> : products }
                    </div>
                </div>
                <div className="row px-xl-5">
                {/* <div className="col-lg-3 col-md-12">
                </div> */}
                    <div className="col-lg-12 col-md-12">
                        { isLoadingProducts ? <LoadingSpinner /> : navBar }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Shop;
