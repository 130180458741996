


import React from 'react'

let padding = {"padding-top": "1rem"};

function Info() {
    return (
        <div class="container-fluid pt-5 bg-secondary-1">
            <div class="row px-xl-5">
                <div class="col-lg-6">
                    <h3>Magazin</h3>
                    <br/>

                    <h4>TGV Service LED-LCD S.R.L. vă pune la dispoziție o gamă variată de componente electronice:</h4>
                    <h5>Telecomenzi, invertoare, barete, surse, cabluri și altele.</h5>
                    <br/>

                    <h4>Componentele se vând in diferite condiții:</h4>
                    <h5>Noi, achiziționate de la producători, second hand, provenite din televizoare funcționale cu display defect.</h5>
                    <br/>
                    <h5>Toate componentele provenite din dezmembrări sunt testate.</h5>
                    
                    <br/>
<div>
<h5><u>Metode de livrare:</u></h5>
Livram pe teritoriul Romaniei orice produs vandut de noi prin curier rapid-Fan Courier. Cost unic de livrare 15 Ron.
<br/>
La cerere,expediem prin oricare alta firma de courierat,tariful depinzand de locatia clientului.
<br/>
Termen de livrare 1-2 zile lucratoare.
<br/>
Comenzile mai mari de 300 Ron beneficiaza de transport gratuit.
<br/> 
<h5><u>Metode de plata:</u></h5>
Ramburs- la courier in momentul primirii coletului
<br/>
<br/>
</div>

		    <div>
			<h5><u>Politica de retur:</u></h5>
			Ai dreptul la retur! Link catre pagina oficiala a Autoritatii Nationale pentru Protectia Consumatorilor: www.anpc.gov.ro
			<br/><br/>
			Esti nemultumit de produsul cumparat de la TGV Service LED-LCD SRL ( Okazii, OLX, www.piesetvledlcd.ro)?  Vrei sa il returnezi si sa primesti banii inapoi? Ai dreptul sa aplici pentru procedura de retur a produselor in maxim 14 zile lucratoare , de la data primirii produselor de catre consumator ( conform dispozitiilor O.U.G. Nr. 34/2014, Privind protectia consumatorilor la incheierea si executarea contractelor la distanta).
			<br/><br/>
			Dreptul de retur in 14 zile lucratoare se adreseaza doar clientilor, persoane fizice, care au achizitionat produse comercializare de TGV Service LED-LCD SRL. 
			<br/>Consumatorul este raspunzator de achitarea cheltuielilor aferente returnarii produselor catre comerciant.
			Dreptul de retur nu se aplica in cazul Presoanele juridice, P.F, S.R.L. S.A.
			<br/><br/><h5><u>Procedura de retur:</u></h5>
			Clientul care returneaza un produs cumparat, va trimite acest produs ambalat corespunzator, la sediul firmei din str. Principala nr. 53, Com. Frumusani, Jud. Calarasi, prin intermediul unei firme de courierat rapid Cargus sau Fan Courier, fara ramburs ( clientul plateste transportul retulului). Plata contravalorii produsului se face in contul bancar specificat de client, numai in urma verificarii conformitatii produsului. In cazul unui produs defect sau a unei greseli din vina noastra, costurile de transport si costul returului sunt suportate integral de noi. 
			<br/><br/>Produsele pentru retur trebuie sa fie insotite de un proces verbal de constatare din partea unui service autorizat , in care sa fie specificate rezultatele testului service si motivul pentru care se cere inlocuirea sau returnarea produsului. 

			<br/><br/>In lipsa acestui document emis de un service autorizat, ne rezervam dreptul de a refuza inlocuirea sau returnarea contravalorii produsului.
			<br/><br/>In cazul in care produsul prezinta urme de uzura, lovituri, socuri mecanice, zgarieturi, accesorii lipsa, interventii neautorizare, ne rezervam dreptul de neacceptare a returului. In situatia unor retururi repetate din partea aceluiasi client ne rezervam dreptul de neacceptare a returului. In cazul in care, acelasi client refuza ridiarea coletului de doua ori, ne rezervam dreptul de a nu mai onora comenzile ulterioare. 
			<br/><br/>In cazul in care clientul comanda un produs, ignorand specificatiile tehnice afisate ( text sau imagine) sau furnizare suplimentar la cerere, ne rezervam dreptul de a nu returna contavaloarea prodului. 
			<br/><br/>Nu oferim produsele noaste pentru teste. Nu oferim produsele noastre pentru a constata ca display-ul este defect sau spart ( exista si alte metode). Nu oferim diagnoza la distanta.
			<br/><br/>Plata contavalorii produselor returnate se efectueaza intr-un cont bancar specificat de client in termen de 14 zile lucratoare. 

			<br/><br/>Va recomandam sa apelati la un service autorizat pentru constatarea defectiunii televizorului.
			<br/><br/>Va stam la dispoziție pentru orice informatie tehnica la adresa de mail piese.tv@yahoo.com , gmail stroe.georgiana93@yahoo.com , tel si whatapp 0729532485.
		<br/>
		<br/>    
		</div>
                </div>
                <div class="col-lg-6">
                    <h3>Service</h3>
                    <br/>
                        <h5>
                        TGV Service LED-LCD SRL vă oferă servicii de reparații televizoare și monitoare LED și LCD, 
                        inclusiv înlocuire backlight și display pentru orice marcă sau model. </h5>
                        <br></br>
                        <h5>Achiziționăm orice model de televizor LED spart sau defect. Efectuăm plata pe loc.</h5>
                        <br/>
                        <h4>Garanție:</h4>
                        <h5>Prestarea de servicii este însoțită și de garanția aferentă între 3-12 luni.</h5>
                       
                        
                </div>
            </div>
        </div>
    )
}



export default Info;