import React, {useState, useEffect} from 'react'
import env from "react-dotenv";
import { useParams } from 'react-router-dom';
import LoadingSpinner from "../components/loadingspinner";

const BE = env.API_ENDPOINT;

const axios = require('axios');

function Tracking() {

    const [order, setOrder] = useState({});
    const [clientInfo, setClientInfo] = useState({});
    const [address, setAddress] = useState({});
    const [orderItemList, setOrderItemList] = useState([]);

    const [isLoadingOrder, setIsLoadingProgress] = useState(false);

    const { id } = useParams();

    useEffect(() => { getOrder(); }, []);

    const getOrder = () => {

        setIsLoadingProgress(true);

        axios.get(BE + `/api/order/` + id).
        then((response) => {
            console.log("..response..");
            console.log(response);

            setOrder(response.data);
            setClientInfo(response.data.clientInfo);
            setAddress(response.data.clientInfo.address);
            setOrderItemList(response.data.orderItemList);
            setIsLoadingProgress(false);
        })
        .catch(function (error) {
            setIsLoadingProgress(false)
            console.log(error);
            console.log('Ups s-a produs o erroare la obtinerea comenzii.');
            /* Show an error here!*/
        });
    }

    const orderTracking = (
        <div class="row px-xl-5">
            <div class="col-lg-8">
                <div class="mb-4">
                    {/* {order} */}
                    <h1>ID: {order.id}</h1>
                    <h1>Status: {order.status}</h1>
                    <h1>AWB: {order.awb}</h1>
                    <h1>Curier: {order.courier}</h1>
                    <h1>Detalii comanda: {order.details}</h1>
                    <h1>Comanda plasata la: {order.dateIssued}</h1>
                    <h1>Comanda trimisa la: {order.dateSent}</h1>
                </div>
                {/* <h2>Lista produse</h2>
                {order.orderItemList != null ? order.orderItemList.map((product) => (
                            <div class="d-flex justify-content-between">
                                <p>{product.product.name}</p>
                                <p>{product.product.price} RON</p>
                                <p>{product.quantity} unitati</p>
                            </div>
                        )) : ""} */}
            </div>
            <div class="col-lg-4">
                <div class="mb-4">
                    {/* {clientInfo} */}
                </div>
            </div>
        </div>
    )

    return (
        <div class="container-fluid pt-5"> 
            { isLoadingOrder ? <LoadingSpinner /> : orderTracking }
        </div>
    )
}

export default Tracking;